import Vue from 'vue'

export default {
    showNotify: function (message, notifyType) {
        let notify = Vue.prototype.$message;
        let type;
        //關閉先前產生的提示視窗
        notify.closeAll();
        //判斷型態帶入不同狀態
        switch (typeof notifyType) {
            case "string": {
                type = notifyType;
                break
            }
            case "boolean": {
                if (notifyType === true) {
                    type = "success";
                    break
                } else {
                    type = "error";
                    break
                }
            }
            default: {
                type = 'error';
                break
            }
        }

        notify({
            type: type,
            message: message,
            duration: 2000,
        });
    },
}
