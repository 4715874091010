import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
//---- Auth
import Auth from './util/auth'
Auth.install = function (Vue) {
  Vue.prototype.$auth = Auth;
};
Vue.use(Auth);

//---- Public
import Public from './util/public'
Public.install = function (Vue) {
  Vue.prototype.$public = Public;
};
Vue.use(Public);

//vue-loading-overlay
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(VueLoading); //Use default options

//---- ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
