import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/202204dashboard',
    name: '202204dashboard',
    component: () => import(/* webpackChunkName: "202204dashboard" */ '../views/202204/dashboard.vue')
  },
  {
    path: '/202204participate',
    name: '202204participate',
    component: () => import(/* webpackChunkName: "202204participate" */ '../views/202204/participate-list.vue')
  },
  {
    path: '/202208dashboard',
    name: '202208dashboard',
    component: () => import(/* webpackChunkName: "202208dashboard" */ '../views/202208/dashboard.vue')
  },
  {
    path: '/202208participate',
    name: '202208participate',
    component: () => import(/* webpackChunkName: "202208participate" */ '../views/202208/participate-list.vue')
  },
  {
    path: '/202211dashboard',
    name: '202211dashboard',
    component: () => import(/* webpackChunkName: "202208dashboard" */ '../views/202211/dashboard.vue')
  },
  {
    path: '/202211participate',
    name: '202211participate',
    component: () => import(/* webpackChunkName: "202208participate" */ '../views/202211/participate-list.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
