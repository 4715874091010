/* eslint-disable semi,no-trailing-spaces,indent,quotes,space-infix-ops,comma-dangle,padded-blocks,no-unused-vars,eol-last,semi-spacing */

import router from "@/router";

let admin_token = window.localStorage.getItem('admin_token');
let account = window.localStorage.getItem('account');

export default {
    isLogin() {
        return admin_token !== null && admin_token !== '';
    },
    setAdminAccount(t) {
        window.localStorage.setItem('account', t);
        account = t;
    },
    setAdminToken(t) {
        window.localStorage.setItem('admin_token', t + '');
        admin_token = t;
    },
    getAdminToken() {
        return admin_token;
    },
    getAdminAccount() {
        return account ?? "";
    },
    clearToken() {
        admin_token = '';
        window.localStorage.removeItem('admin_token');
        router.replace("/")
    },
}
